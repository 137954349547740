<template>
    <div>
        <v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear> 
        <v-container v-else class="my-2">
            <v-layout row wrap px-4 mb-4>
                <v-flex xs9>
                    <v-breadcrumbs class="px-0" :items="$store.getters.language.data.profile.list_path">
                        <template v-slot:item="{item}">
                            <v-breadcrumbs-item :to="item.path" :disabled="item.disabled" exact>
                                {{ item.title }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                </v-flex>
                <v-flex xs3></v-flex>
            </v-layout>
            <v-layout row wrap>
                <v-flex xs12 lg6 md6 sm12 class="px-2">
                    <v-card class="py-2">
                        <v-card-text>
                            <v-text-field v-model="store.store_name" :label="$store.getters.language.data.profile.store_name" dense
                                class="mx-1"  filled outlined required>
                            </v-text-field>
                            <v-text-field v-model="store.store_dolar_price" :label="$store.getters.language.data.profile.store_dolar_price" dense
                                class="mx-1"  filled outlined required>
                            </v-text-field>
                            <v-textarea rows="3" v-model="store.store_about" :label="$store.getters.language.data.profile.store_about" dense
                                class="mx-1"  filled outlined required>
                            </v-textarea>
                            <v-textarea rows="3" v-model="store.store_url" :label="$store.getters.language.data.profile.store_url" dense
                                class="mx-1"  filled outlined required>
                            </v-textarea>
                            <span class="mx-2"> {{ $store.getters.language.data.profile.store_logo }}: </span>
                            <v-autocomplete filled outlined dense v-model="store.store_logo_text" :items="file_attachments" item-text="file_attachment_name" item-value="file_attachment_path">
                                <template v-slot:item="{item}">
                                    <v-img :src="$image_url + 'file_attachments/serve_file/' + item.file_attachment_path" max-width="60" contain></v-img>
                                    <span class="ms-1"> {{ item.file_attachment_name }} </span>
                                </template>
                            </v-autocomplete>
                            <v-img v-if="store.store_logo" :src="$image_url + 'file_attachments/serve_file/' + store.store_logo" aspect-ratio="2" contain></v-img>

                            <span class="mx-2"> {{ $store.getters.language.data.profile.store_invoice_header }}: </span>
                            <v-autocomplete filled outlined dense v-model="store.store_invoice_header_text" :items="file_attachments" item-text="file_attachment_name" item-value="file_attachment_path">
                                <template v-slot:item="{item}">
                                    <v-img :src="$image_url + 'file_attachments/serve_file/' + item.file_attachment_path" max-width="60" contain></v-img>
                                    <span class="ms-1"> {{ item.file_attachment_name }} </span>
                                </template>
                            </v-autocomplete>
                            <v-img v-if="store.store_invoice_header" :src="$image_url + 'file_attachments/serve_file/' + store.store_invoice_header" aspect-ratio="2" contain></v-img>

                            <v-btn color="success" :loading="loading_btn" @click="update_stores()" block> {{ $store.getters.language.data.stores.update_btn }} </v-btn>
                        </v-card-text>
                    </v-card>
                </v-flex>
                <v-flex xs12 lg6 md6 sm12 class="px-2">
                    <v-card class="py-2">
                        <v-card-text>
                            <h3 class="my-2"> 
                                {{ $store.getters.language.data.profile.store_join_date }}:
                                {{ new Date(store.store_join_date).toISOString().split('T')[0] }}
                            </h3>

                            <h3 class="my-5"> 
                                {{ $store.getters.language.data.profile.store_renew_date }}:
                                {{ new Date(store.store_renew_date).toISOString().split('T')[0] }}
                            </h3>

                            <h3 class="my-5"> 
                                {{ $store.getters.language.data.profile.available_until }}:
                                {{ calculateExpireDate(store.store_renew_date) }} {{ $store.getters.language.data.profile.days }}
                                
                            </h3>

                            <h3 class="my-5"> 
                                {{ $store.getters.language.data.profile.store_available_to_shop }}:
                                <v-icon v-if="store.store_available_to_shop == 'true'" color="green" size="20"> mdi-check-circle </v-icon>
                                <v-icon v-else color="error" size="20"> mdi-close-circle </v-icon>
                            </h3>

                            <h3 class="my-5"> 
                                {{ $store.getters.language.data.profile.store_use_barcode }}:
                                <v-icon v-if="store.store_use_barcode == 'true'" color="green" size="20"> mdi-check-circle </v-icon>
                                <v-icon v-else color="error" size="20"> mdi-close-circle </v-icon>
                            </h3>

                            <h3 class="my-5"> 
                                {{ $store.getters.language.data.profile.store_use_expire_date }}:
                                <v-icon v-if="store.store_use_expire_date == 'true'" color="green" size="20"> mdi-check-circle </v-icon>
                                <v-icon v-else color="error" size="20"> mdi-close-circle </v-icon>
                            </h3>

                            <h3 class="my-5"> 
                                {{ $store.getters.language.data.profile.store_sell_type }}:
                                {{ store.store_sell_type | sell_type_filter }}
                            </h3>

                            <h3 class="my-5"> 
                                {{ $store.getters.language.data.profile.store_payment }}:
                                {{ store.store_payment }}
                            </h3>
                            
                            <h3 class="my-5"> 
                                {{ $store.getters.language.data.profile.store_print_type }}:
                                {{ store.store_print_type | print_type_filter }}
                            </h3>

                            <h3 class="my-5"> 
                                {{ $store.getters.language.data.profile.store_item_limit }}:
                                {{ store.store_item_limit  }}
                            </h3>

                            <h3 class="my-5"> 
                                {{ $store.getters.language.data.profile.store_order_limit }}:
                                {{ store.store_order_limit  }}
                            </h3>
                                
                        </v-card-text>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
import moment from 'moment'
import requests from '../requests/stores.request'
export default {
    data() {
        return {
            loading: false,
            store: {},
            loading_btn: false
        }
    },
    computed: {
        file_attachments() {
            return this.$store.getters.file_attachments_list
        }
    },
    mounted() {
        this.store = JSON.parse(JSON.stringify(this.$store.getters.store))
    },
    methods: {
        // Calculate expire date from now to +1Year of renew date
        calculateExpireDate(renew_date) {
            var end_date = new Date(renew_date);
            end_date.setFullYear(end_date.getFullYear() + 1);
            var now = new Date();
            var start = moment(end_date, "YYYY-MM-DD");
            var end = moment(now, "YYYY-MM-DD");
            return Math.floor(moment.duration(start.diff(end)).asDays());
        },
        update_stores() {
            this.loading_btn = true;
            const store = JSON.parse(JSON.stringify(this.store));

            /*
                Logo and invoice header text seperated because, the image must shown and path to update
                is seperate from image
            */ 
            store.store_logo = store.store_logo_text 
            store.store_invoice_header = store.store_invoice_header_text 

            delete store.store_logo_text;
            delete store.store_invoice_header_text;

            requests.updateStores(this.store.store_id, store).then(r => {
                this.store = r.data.new_data;
            }).finally(() => {
                this.loading_btn = false;
            })
        }
    },
    filters: {
        sell_type_filter(value) {
            return {
                all: 'Cash, Loan, Diposit',
                qarz: 'Cash, Loan',
                naqd: 'Cash',
                qist: 'Cash, Diposit',
            }[value]
        },
        print_type_filter(value) {
            return {
                a4: 'A4',
                mini: 'Mini',
                a4_mini: 'A4, Mini'
            }[value]
        }
    }
}
</script>
