
import axios from 'axios';
export default {
    
        async createStores(params)  {
            return await axios.post(`stores/create` , params)
        },
        async createStoresList(params)  {
            return await axios.post(`stores/create/list` , params)
        },
        async updateStoresColumn(column , value , data)  {
            return await axios.put(`stores/update_list?${column}=${value}` , data)
        },
        async deleteStoresList(list)  {
            return await axios.delete(`stores/delete_list` , {
                data: {
                    list:list
                }
            })
        },
        async reportStores(column , value)  {
            return await axios.get(`stores/report?${column}=${value}`)
        },
        async getAllStores()  {
            return await axios.get(`stores/all`)
        },
        async getOneStores(store_id)  {
            return await axios.get(`stores/all/${store_id}`)
        },
        async getStoresByColumn(column , value)  {
            return await axios.get(`stores/filter?column=${column}&value=${value}`)
        },
        async deleteStores(store_id)  {
            return await axios.delete(`stores/delete/${store_id}`)
        },
        async updateStores(store_id , params)  {
            return await axios.put(`stores/update/${store_id}` , params)
        },
}
        